import styled, { css } from "styled-components";
import { theme } from '../../styled/theme'

export interface IMenu { active?: boolean }

export type DefaultProps = {} & Pick<IMenu, 'active'>

export const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

export const LI = styled.li<DefaultProps>`
  cursor: pointer;
  list-style: none;
  background: ${theme.secondary[0]};
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: ${theme.fontSize.medium};
  color: #FFF; 

  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

  ${({ active }) => active && css`
    background: ${theme.primary[0]};
  `}

  :hover {
    background: ${theme.primary[0]};
  }
`;