import { Wrapper, FooterType } from './styled'
import { P } from '../../styled/globalStyles'

export const Footer = ({ reference = 'main', marginTop }: FooterType) => {

  return (
    <Wrapper reference={reference} marginTop={marginTop} >
      <P>
        suporte@muvrental.com.br<br /> 
        <span>Copyright @ Muv Rental - 2022</span>
      </P>
    </Wrapper> 
  )
}