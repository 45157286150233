import { createContext, useCallback, useState } from "react";
import { StateType, PageLoadingType} from './props'

const DEFAULT_VALUE = {  
  state: {
    active: false,
  },
  toggle: () => {},
}

const PageLoadingContext = createContext<PageLoadingType>(DEFAULT_VALUE);

interface Props {
  children: React.ReactNode
}
const PageLoadingProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<StateType>(DEFAULT_VALUE.state); 
  const toggle = useCallback(
    (settings: StateType) => {
      setState(settings)
    },
    [setState]
  )

  return (
    <PageLoadingContext.Provider value={{ state, toggle }}>
      {children}
    </PageLoadingContext.Provider>
  )
}

export { PageLoadingProvider };
export default PageLoadingContext;