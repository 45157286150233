import { createContext, useCallback } from 'react';
import { IAuthProvider, IContext, IUser, TipoUsuarioDescicaoEnum } from "./types";
import { useLocalStorage } from "react-use";
import { removeAuthenticatedUser } from '../../localStorage/authentication';

const DEFAULT_VAUE = {
  user: {
    cargo: "",
    celular: "",
    cpf_cnpj: "",
    created_at: "",
    eh_representante: "",
    email: "",
    empresa: "",
    endereco: "",
    id: "",
    modalidade: "",
    nome: "",
    telefone: "",
    token: "",
    tipo_usuario_descricao: TipoUsuarioDescicaoEnum.DESLOGADO,
    tipo_usuario: 0,
    updated_at: "",
    locatario: {},
    locador: {},
  },
  authenticate: () => { },
  logout: () => { },
}

const AuthContext = createContext<IContext>({} as IContext);

const AuthProvider = ({ children }: IAuthProvider) => {
  const [user, setUser] = useLocalStorage<IUser>('muvrental-app-web-2021', DEFAULT_VAUE.user)

  const authenticate = useCallback((user: IUser) => {
    setUser(user)
  }, [setUser])

  function logout() {
    removeAuthenticatedUser()
    setUser(DEFAULT_VAUE.user)

    window.location.href = "/";
  }

  return (
    <AuthContext.Provider value={{ user, authenticate, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider };
export default AuthContext;