import { Fragment, useState } from 'react';
import { Container, Row, Col, Div } from '../../styled/globalStyles';
import { Wrapper, WrapperLink, Nav, WrapperMenuMobile, WrapperContentMobile, ListLinksMobile } from './styled';
import { Link, useHistory } from 'react-router-dom';
import { Button, SidebarLocador, SidebarLocatario, SidebarAdministrador } from '../';
import { IoMenuOutline } from 'react-icons/io5';
import logo from '../../assets/imagens/logo.jpg'; 
import { useAuth } from './../../context/auth/useAuth';
import { getFirstName } from './../../helpers/index'; 

import { Link as ReactScrollLink } from 'react-scroll'
 
export const Header = () => {   
  const [isMenuMobileActive, setIsMenuMobileActive] = useState(false);
  const { logout, user } = useAuth(); 
  const handleLogout = () => logout() 

  const handleMenuMobile = () => {
    setIsMenuMobileActive(!isMenuMobileActive)
    document.getElementById('menu-mobile').style.display = isMenuMobileActive ? 'block' : 'none'
  }
   
  return (
    <Fragment>
      <Wrapper className="container-fluid">
        <Container className="container">
          <Row className="row">  
            <Col className="col-4 col-sm-4 col-md-4 col-lg-3 col-lx-3 col-xxl-3"> {/* d-xxl-block d-lx-block d-lg-block d-none */}
              Olá, {getFirstName(user.nome)}
            </Col>
            <Col className="col-8 col-sm-8 col-md-8 col-lg-9 col-lx-9 col-xxl-9 text-right">
              <WrapperLink className="d-flex justify-content-end">
                {/* <Link to="#"> 
                  <IoNotifications size={25} /> 
                  <span>NOTIFICAÇÕES</span> 
                </Link> */}
                <Button 
                  label="SAIR" 
                  onClick={() => handleLogout()}  
                />
                <IoMenuOutline size={25} className="menu-mobile" onClick={() => handleMenuMobile()} />
              </WrapperLink> 
            </Col>
          </Row>
        </Container> 
      </Wrapper> 
      <WrapperMenuMobile id="menu-mobile" style={{ display: 'none' }}>
        {/* { user.modalidade === 'Locador' ? <SidebarLocador /> : <SidebarLocatario /> }  */}
        {/* { user?.locador ? <SidebarLocador /> : <SidebarLocatario /> }  */}
        {user?.locador &&  <SidebarLocador />}
        {user?.locatario &&  <SidebarLocatario />}
        {user?.administrador &&  <SidebarAdministrador />}

      </WrapperMenuMobile>
    </Fragment>
  )
}

export const HeaderCadastro = () => {
  return (
    <Div className="container-fluid pt-4">
      <Div className="container">
        <Div className="row">
          <Div className="col-12">
            <Link to="/">
              <img src={logo} alt="imagem - logomarca" title="MuvRental" />
            </Link>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export const HeaderLandingPage = () => {
  const [isMenuMobileActive, setIsMenuMobileActive] = useState(false);
  const history = useHistory();

  const handleMenuMobile = () => {
    setIsMenuMobileActive(!isMenuMobileActive)
    document.getElementById('menu-mobile').style.display = isMenuMobileActive ? 'block' : 'none'
  }

  return (
    <Fragment>
      <Wrapper className="container-fluid"> 
        <Container className="container">
          <Row className="row d-flex align-items-center">  
            <Col className="col-6 col-sm-3 col-md-3 col-lg-3 col-lx-3 col-xxl-3">
              <img width={150} src={logo} alt="imagem - logomarca" title="MuvRental" />
            </Col>
            <Col className="col-5 d-none d-lg-block">
              <Nav>
                <Link to="#" className="active link">HOME</Link>
                <ReactScrollLink className='link' to="sobre" spy={true} smooth={true} duration={500} style={{ cursor: 'pointer' }}>SOBRE</ReactScrollLink> 
                <ReactScrollLink className='link' to="contato" spy={true} smooth={true} duration={500} style={{ cursor: 'pointer' }}>CONTATO</ReactScrollLink>
              </Nav>
            </Col> 
            <Col className="col-4 text-right d-none d-lg-block">   {/* col-sm-4 col-md-4 col-lg-4 col-lx-4 col-xxl-4  */}
              <WrapperLink className="d-flex justify-content-end" style={{ display: 'flex', gap: '20px' }}> 

                <Button label="CADASTRE-SE" size="medium" onClick={() => history.push('/criar-conta')} />

                <Button label="LOGIN" size="medium"  onClick={() => history.push('/login')}  /> 
              </WrapperLink> 
            </Col>
            <Col className='col-6 d-block d-lg-none text-end'>
              <IoMenuOutline size={25} className="menu-mobile" onClick={() => handleMenuMobile()} />
            </Col>
          </Row>
        </Container> 
      </Wrapper>
      <WrapperMenuMobile id="menu-mobile" style={{ display: 'none' }}>
        <WrapperContentMobile> 
          <ListLinksMobile>
            <Link to="#" className="active">HOME</Link>
            <Link to="#">SOBRE</Link> 
            <Link to="#">CONTATO</Link>
          </ListLinksMobile>
          <Button isFull label="CADASTRE-SE" size="medium" onClick={() => history.push('/criar-conta')} />
          <Button isFull label="LOGIN" size="medium"  onClick={() => history.push('/login')}  />  
        </WrapperContentMobile>
      </WrapperMenuMobile>
    </Fragment>
  )
}