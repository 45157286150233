import styled from "styled-components"; 
import { theme } from './../../styled/theme';

export const Wrapper = styled.div`
  cursor: pointer;
  width: 100%;
  border-radius: 15px;
  border: 2px solid #FFF;
  display: flex;
  justify-content: space-between;
`;

interface IBox {
  active: boolean
}

export const Box = styled.div<IBox>`
  font-size: 11px;
  background: ${({ active }) => active && theme.secondary[0] };
  text-align: center;
  width: 50%;
  border-radius: 15px;
  padding: 5px;
`;