import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalContext from './context/'
import ReactGA from "react-ga4"
import TagManager from 'react-gtm-module'

ReactGA.initialize('G-78H5W7KLHR')

const tagManagerArgs = {
  gtmId: 'GTM-TKZ5BDSX',

}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <StrictMode>
    <GlobalContext>
      <App />
    </GlobalContext>
  </StrictMode>,
  document.getElementById('root')
);
