export const hasFilters = (values, pageNumber = 0) => {
  let filtros = ''
  if (Object.keys(values).length !== 0) {
    let loop = 0;
    for (var key in values) {

      if (values[key] !== undefined && values[key] !== null || values[key] === '') {
        if (values[key].value === 2 && key === 'ativo') {
          continue;
        }
        if (loop === 0 && pageNumber > 0) {
          filtros += '&filtros=';
        } else {
          filtros += '?filtros=';
        }

        filtros += (key === 'ativo') ? `${key}:=:${values[key].value};` : `${key}:like:${values[key]}%;`;
        loop++;
      }

    }
    filtros = filtros.substring(0, filtros.length - 1);
  }

  return filtros;
}