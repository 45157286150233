import { Wrapper } from './styled'
import { P, Strong } from '../../styled/globalStyles'
import { usePageLoading } from '../../context/pageLoading/usePageLoading'

export const LoadingPage = () => {
  const { state } = usePageLoading();

  return (
    <Wrapper active={state.active}>
      <P color='#fff'>
        <Strong>
          ... Carregando
        </Strong>
      </P>
    </Wrapper>
  )
} 