import styled, { css } from "styled-components";
//import { theme } from "../../styled/theme";

interface IPageLoading {
  active: boolean
}

export const Wrapper = styled.div<IPageLoading>`

  ${({ active }) => css`
    display: ${active ? 'flex' : 'none'};
  `}

  width: 100%;
  height: 100vh;
  position: fixed; 
  justify-content: center;
  align-items: center;
  z-index: 9999; 
  background: rgba(0,0,0,0.8)}
`;