import { useState } from 'react'
import { toast } from 'react-toastify';
import { alterarDisponibilidade } from '../../services/maquinaRequest';
//import { changeModelActive } from '../../services/modelosRequest';
import { Wrapper, Box } from './styled'

interface ISwitch {
  available: boolean 
  id: any
}

export const Switch = ({ available, id }:ISwitch) => {
  const [fetching, setFetching] = useState(false)
  const [isAvailable, setIsAvailable] = useState(available); 
 
  const handleClick = () => {
    setFetching(true)
    setIsAvailable(!isAvailable) 
    alterarDisponibilidade(id).then(({ data }) => {
      toast.success('Disponibilidade alterada.')
    }).catch(err => {
      console.log(err)
    }).finally(() => setFetching(false))
  }
  
  if (fetching) return <p> ... Atualizando</p>
  return (
    <Wrapper onClick={() => handleClick()}>
      <Box active={isAvailable ? true : false}>disponível</Box>
      <Box active={!isAvailable ? true : false}>indisponível</Box>
    </Wrapper>
  )
}


export const SwitchActive = ({available, id, fetching, handleActive}) => {

  if(fetching) return <p>...Atualizando</p>
  return (
    <Wrapper onClick={() => handleActive(id)}>
      <Box active={available ? true : false}>Sim</Box>
      <Box active={!available ? true: false}>Não</Box>
    </Wrapper>
  )
}