import { AuthProvider } from './auth/context'
import { PageLoadingProvider } from "./pageLoading/context"

const GlobalContext = ({ children }: { children : JSX.Element }) => {
  return ( 
    <AuthProvider>
      <PageLoadingProvider>
        {children}
      </PageLoadingProvider> 
    </AuthProvider>  
  )
}

export default GlobalContext;