export const getFirstName = (name: string) => {
  let firstName = name.split(" "); 
 
  return firstName[0];
}

export const removeSpecialCharacters = (string: string) => {
  var er = /[^a-z0-9]/gi;
  string = string.replace(er, "");
  
  return string
}

export const blobToBase64 = (url:any, callback:any) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
} 

export const gotPhoto = (element: any, key: string) => {
  var file = element.files[0];
  var reader = new FileReader()
  reader.onload = function(base64) {
     localStorage[key] = base64;
  }
  reader.readAsDataURL(file);
}

export const getFileToBase64 = (key: string) => {
  var base64 = localStorage[key];
  var base64Parts = base64.split(",");
  var fileFormat = base64Parts[0].split(";")[1];
  var fileContent = base64Parts[1];
  var file = new File([fileContent], "file name here", {type: fileFormat});

  return file;
}