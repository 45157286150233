/* Rules

 Gestor
  - Tudo c/ Filtro por usuário
  - Meus Dados (retirar a parte de subir documentos)
 
 Engenheiro
  - Tudo - Menos (Meus Usuários)
  - Meus Dados (so retirar a parte de subir documentos)
*/

import { TipoUsuarioDescicaoEnum } from "../context/auth/types"

const GESTOR = ['INICIO', 'MEUS USUÁRIOS', 'MINHAS CONTRATAÇÕES', 'SERVIÇOS AGENDADOS', 'DASHBOARD', 'MEUS DADOS']
const ENGENHEIRO = ['INICIO', 'MINHAS CONTRATAÇÕES', 'SERVIÇOS AGENDADOS', 'DASHBOARD', 'MEUS DADOS']

export const hasAccess = (feature: string, userType: TipoUsuarioDescicaoEnum) => {
  
  if (userType === TipoUsuarioDescicaoEnum.ENGENHEIRO) return ENGENHEIRO.includes(feature)

  if (userType === TipoUsuarioDescicaoEnum.GESTOR) return GESTOR.includes(feature)

  return true
}
