import { UL, LI } from './styled';
import { Link, useParams } from 'react-router-dom';
import { hasAccess } from '../../helpers/hasAccess';
import { useAuth } from '../../context/auth/useAuth';

interface ParamTypes { id?: string }

export const SidebarLocador = () => {
  let { id } = useParams<ParamTypes>();
  const urldomain = window.location.href.split('/#');
  const page = urldomain[1]

  return (
    <UL>   
      <Link to="/locador/servicos-em-execucao">
        <LI active={page === '/locador/servicos-em-execucao' || page === '/locador/'  ? true : false}>
          SOLICITAÇÕES DE SERVIÇOS
        </LI>
      </Link>
      
      <Link to="/locador/bid">
        <LI active={
          page === '/locador/bid' ||
          page === `/locador/bid/detalhe/${id}`? true : false}>
          ORÇAMENTO BID
        </LI>
      </Link>

      <Link to="/locador/servicos-agendados">
        <LI active={page === '/locador/servicos-agendados' ? true : false}>
          MEUS SERVIÇOS
        </LI>
      </Link>

      <Link to="/locador/meus-equipamentos">
        <LI 
          active={
            page === '/locador/meus-equipamentos' || 
            page === '/locador/meus-equipamentos/cadastrar' || 
            page === `/locador/meus-equipamentos/${id}/editar` ? true : false}
          >
          MEUS EQUIPAMENTOS
        </LI> 
      </Link>

      <Link to="/locador/itens">
        <LI
          active={
            page === '/locador/itens' ? true : false
          }
          >
          ITENS ADICIONAIS
        </LI>
      </Link>

      <Link to="/locador/meus-operadores">
        <LI 
          active={
            page === '/locador/meus-operadores' || 
            page === '/locador/meus-operadores/cadastrar' || 
            page === `/locador/meus-operadores/${id}/editar` ? true : false}
          >
          MEUS OPERADORES
        </LI>
      </Link>
 
      <Link to="/locador/dashboard">
        <LI  active={page === '/locador/dashboard' ? true : false}>
          DASHBOARD
        </LI>
      </Link>

      <Link to="/locador/meus-dados">
        <LI active={page === '/locador/meus-dados' ? true : false}>
          MEUS DADOS
        </LI> 
      </Link>  
    </UL> 
  )
}

export const SidebarAdministrador = () => {
  const urldomain = window.location.href.split('/#');
  const page = urldomain[1]
  let { id, empresaId, idHomologado, idLocador } = useParams<{id, empresaId, idHomologado, idLocador}>();

  return (
    <UL>
      <Link to="/administrador">
        <LI active={
          page === '/administrador' ||
          page === `/administrador/${id}/orcamento` ? true: false}>
          INÍCIO
        </LI>
      </Link>
        <Link to="/administrador/novos-usuarios">
            <LI active={
                page === `/administrador/novos-usuarios` ? true : false
            }>
                NOVOS USUÁRIOS
            </LI>
        </Link>
      <Link to="/administrador/bid">
        <LI active={
          page === '/administrador/bid' ||
          page === `/administrador/bid/${id}` ||
          page === `/administrador/bid/${id}/detalhe` ? true : false
        }>
          ORÇAMENTO BID
        </LI>
      </Link>
      <Link to="/administrador/usuarios">
        <LI active={page === '/administrador/usuarios' ? true : false}>
          USUÁRIOS ADM
        </LI>
      </Link>
      <Link to="/administrador/modelos">
        <LI active={page === '/administrador/modelos' ? true: false}>
          MODELOS
        </LI>
      </Link>
      <Link to="/administrador/itens">
        <LI active={page === '/administrador/itens' ? true: false}>
          ITENS ADICIONAIS
        </LI>
      </Link>
      <Link to="/administrador/locatarios">
        <LI active={page === '/administrador/locatarios' ||
                    page === `/administrador/locatarios/${id}/detalhe` ||
                    page === `/administrador/locatarios/${id}/detalhe/${empresaId}/homologados` ||
                    page === `/administrador/locatarios/${id}/detalhe/homologados/equipamentos/${idHomologado}/${idLocador}` ||
                    page === `/administrador/locatarios/${id}/detalhe/homologados/adicionais/${idHomologado}/${idLocador}` ? true : false}>
          LOCATÁRIOS
        </LI>
      </Link>
      <Link to="/administrador/locadores">
        <LI active={page === '/administrador/locadores' ||
                    page === `/administrador/locadores/${id}/detalhe` ||
                    page === `/administrador/locadores/${id}/operadores` ||
                    page === `/administrador/locadores/${id}/operadores/detalhe` ||
                    page === `/administrador/locadores/${id}/equipamentos` ? true : false}>
          LOCADORES
        </LI>
      </Link>
      <Link to="/administrador/meus-dados">
        <LI active={page === '/administrador/meus-dados' ? true : false}>
          MEUS DADOS
        </LI>
      </Link>    
    </UL>
  )
}
  
export const SidebarLocatario = () => { 
  let { id } = useParams<ParamTypes>();
  const urldomain = window.location.href.split('/#');
  const page = urldomain[1]

  const { user } = useAuth();

 

  return (
    <UL>
      {
        user &&
        hasAccess('INICIO', user?.tipo_usuario) &&
        <Link to="/locatario">
          <LI  active={page === '/locatario' ? true : false}>
            INÍCIO
          </LI>  
        </Link>

      } 

      <Link to="/locatario/bid">
        <LI active={
          page === '/locatario/bid' ||
          page === `/locatario/bid/detalhe/${id}` ||
          page === `/locatario/bid/detalhe/bid-response/${id}` ||
          page === '/locatario/bid/new' ? true : false}>
            ORÇAMENTO BID
        </LI>
      </Link>  
    
      {        
      user &&
        hasAccess('MEUS USUÁRIOS', user?.tipo_usuario) &&
        <Link to="/locatario/usuarios">
          <LI 
            active={
              page === '/locatario/usuarios' || 
              page === '/locatario/usuarios/cadastrar' || 
              page === `/locatario/usuarios/${id}/editar` ? true : false}
            >
            MEUS USUÁRIOS
          </LI>
        </Link>
      }


      {
        user &&
        hasAccess('MINHAS CONTRATAÇÕES', user?.tipo_usuario) &&
        <Link to="/locatario/minhas-contratacoes">
          <LI active={
            page === '/locatario/minhas-contratacoes' ||
            page === `/locatario/minhas-contratacoes/${id}/orcamento` ? true : false}>
            MINHAS CONTRATAÇÕES
          </LI>  
        </Link>
      }

      {
        user &&
        hasAccess('MINHAS CONTRATAÇÕES', user?.tipo_usuario) &&
        <Link to="/locatario/servicos-agendados">
          <LI active={page === '/locatario/servicos-agendados' ? true : false}>
            SERVIÇOS AGENDADOS
          </LI>  
        </Link> 
      }

      {
        user &&
        hasAccess('DASHBOARD', user?.tipo_usuario) &&
        <Link to="/locatario/dashboard">
          <LI active={page === '/locatario/dashboard' ? true : false}>
            DASHBOARD
          </LI>
        </Link> 
      }

      {
        user &&
        hasAccess('MEUS DADOS', user?.tipo_usuario) &&
        <Link to="/locatario/meus-dados">
          <LI active={page === '/locatario/meus-dados' ? true : false}>
            MEUS DADOS
          </LI>
        </Link> 
      } 
    </UL> 
  )
}


