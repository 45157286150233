const aplicationName = 'muvrental-app-web-token'

export const setAuthenticatedUser = (tokenJwt: string) => (
  localStorage.setItem(aplicationName, tokenJwt)
)

export const getAuthenticatedUser = () => (
  localStorage.getItem(aplicationName)
)

export const removeAuthenticatedUser = () => (
  localStorage.removeItem(aplicationName)
)