import styled, { css } from "styled-components";
import { ICard } from './props'
import { theme } from "../../styled/theme";

const modiifiers = {
  light: () => css`
    background: #fff;
    color: ${theme.primary[0]};
  `,
  primary: () => css`
    background: ${theme.primary[0]};
    color: #FFF;
  `,
  secondary: () => css`
    background: ${theme.secondary[0]};
  `,

  isNotification: () => css`
    text-align: center;
    padding: 180px;

    @media (max-width: 800px) {
      padding: 50px 20px;

      h1 {
        line-height: 10mm;
      }
    }
  `,
}

export const Wrapper = styled.div<ICard>`
  ${({ variant, isNotification }) => css`
    padding: 20px;
    border-radius: 15px;
  
    ${variant === 'light' && modiifiers.light()}
    ${variant === 'primary' && modiifiers.primary()}
    ${variant === 'secondary' && modiifiers.secondary()}
    ${isNotification && modiifiers.isNotification()}
  `}


  .list-group-item {
    background: none;
    color: #FFF;
    border-bottom: 1px solid #FFF;
  }
`;