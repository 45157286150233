import { hasFilters } from '../helpers/Filters';
import Api from "./api";

// E Q U I P A M E N T O S

export const getMaquinas = async () => {
  const data = await Api.get(`/maquinas`)

  return data;
}

export const getMaquinasPorLocador = async (idLocador: any, values) => {
  let filtros = hasFilters(values)
  const data = await Api.get(`/maquinas/por-locador/${idLocador}${filtros}`)

  return data;
}

export const getMaquinaPorLocadorPaginado = async (idLocador: any, pageNumber: any) => {
  let page = `?paginado=1&page=${pageNumber}`;
  const { data } = await Api.get(`/maquinas/por-locador/${idLocador}${page}`)

  return data;
}

export const getMaquina = async (maquinaId: any) => {
  const data = await Api.get(`/maquinas/${maquinaId}`)

  return data;
}

export const alterarDisponibilidade = async (maquinaId: any) => {
  const data = await Api.post(`/maquinas/alterar-disponibilidade/${maquinaId}`)

  return data;
}

export const createMaquina = async (values: any) => {
  const data = await Api.post("/maquinas", values)

  return data;
}

export const updateMaquina = async (values: any, maquinaId: any) => {
  const data = await Api.post(`/maquinas/${maquinaId}`, values)

  return data;
}

export const deleteMaquina = async (maquinaId: any) => {
  const data = await Api.delete(`/maquinas/${maquinaId}`);

  return data;
}

export const getMaquinaByLocadorByModel = async (locadorId, modeloId) => {
  const { data } = await Api.get(`/maquinas/por-locador/${locadorId}/modelo/${modeloId}`)
  return data;
}