import axios from "axios"; 
import { getAuthenticatedUser, removeAuthenticatedUser } from "../localStorage/authentication";

const Api = axios.create({ 
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
})

Api.interceptors.request.use((config) => {   
  const accessToken = getAuthenticatedUser() 

  if(accessToken) config.headers["Authorization"] = 'Bearer ' + accessToken 

  return config;
})
 
Api.interceptors.response.use(response => { 
  return response;
}, error => {
  if (error.response && error.response.data) {
    if (typeof error !== 'undefined') {  
      if (error.response.status === 401) {
        //@ts-ignore
        window.location = "/#/login"
        removeAuthenticatedUser()
      } 
    }
  }

  return Promise.reject(error)
}) 
 
export const transformMyApiErrors = (errors:any, setFieldError:any) => { 
  console.log(errors.response.data.data)

  Object.entries(errors.response.data.data).forEach(([key, value]) => {
    //@ts-ignore
   setFieldError(key, value[0])
  });  
}

export const transformMyApiErrors1 = (errors:any, setFieldError:any) => {

  Object.entries(errors.response.data.data).forEach(([key, value]) => {
    //@ts-ignore


    setFieldError(value[0])
  });
}











export default Api;