import styled, { createGlobalStyle, css } from "styled-components";
import { theme } from "./theme";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    text-decoration: none;
  }

  .input-range__slider {
    background: #FF9A2E !important;
    border: 1px solid #FF9A2E !important;
  }

  .input-range__track--active {
    background: #FF9A2E !important;
  }

  .input-range__label{
    color: #FFF !important;
  }

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${theme.secondary[0]};
  }

  a {
    text-decoration: none;
    text-decoration-line: none;
  }

  button {
    text-decoration: none;
  }

  body {
    font-family: ${theme.fonts.montserrat};
    background: ${theme.background};
    font-size: 1.125rem;
    text-decoration: none;
  }

  .content-wrapper {
    width: 100% ;
    max-width: 1440px;

    margin: 0 auto;
  }

  .button-pattern {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;

    min-width: 8rem;

    padding: .4rem 1rem;
    border-radius: .7rem;

    &:hover {
      color: #ffffff;
    }
  }

  button.blue-background-hover-effect, a.blue-background-hover-effect {
    background-color: ${theme.primary[0]};

    &:hover {
      background-color: ${theme.primary[1]};
    }
  }

  button.orange-background-hover-effect, a.orange-background-hover-effect {
    background-color: ${theme.secondary[0]};

    &:hover {
      background-color: ${theme.secondary[1]};
    }
  }

  button.green-background-hover-effect, a.green-background-hover-effect {
    background-color: ${theme.green[0]};

    &:hover {
      background-color: ${theme.green[1]};
    }
  }
`;

export const BodyRegister = styled.div`
  color: #fff;
`;

export const ContainerFluid = styled.div`
  min-height: 100vh;
  margin-bottom: -250px !important;
`;
export const Container = styled.div``;
export const Row = styled.div``;
export const Col = styled.div``;

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  
  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: .8rem;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Posiciona o tooltip acima do elemento pai */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  :hover .tooltiptext{
    visibility: visible;
    opacity: 1;
  }
`
export const Strong = styled.strong`
  .color-secondary {
    color: ${theme.secondary[0]};
  }
`;
export const Div = styled.div``;

interface ISpan {
  color?: string;
}
export const Span = styled.span<ISpan>`
  ${({ color }) => css`
    color ${color}!important;
  `}
`;

interface Ip {
  color?: string;
  size?: string;
}
export const P = styled.p<Ip>`
  ${({ color, size }) => css`
    padding: 0;
    margin: 0;
    color ${color}!important;
    font-size: ${size}
  `}
`;

interface IH1 {
  color?: string;
}
export const H1 = styled.h1<IH1>`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.large};
  padding: 0;
  margin: 0;

  ${({ color }) => css`
    color ${color} !important;
  `}
`;

interface ITitlePage {
  color?: string;
}
export const TitlePage = styled.h1<ITitlePage>`
  ${({ color }) => css`
    color: ${color ? color : theme.primary[0]};
  `}

  font-size: ${theme.fontSize.ultra};
  font-weight: ${theme.fontWeight.large};
  padding: 0;
  margin: 0;
`;

export default GlobalStyle;
