import styled, { css } from "styled-components"; 
import { theme } from '../../styled/theme'

export type FooterType = {
  reference?: 'main' | 'register'
  marginTop?: number
}
 
export const Wrapper = styled.footer<FooterType>`
  margin-top: 20px;
  text-align: center;
  padding: 20px 0;
  height: 150px;

  //  margin-top: ${({ marginTop }) => `${marginTop}px`};
   
  ${({ reference }) => css `
    background: ${reference === 'main' ? theme.light[0] : theme.primary[0] }
  `}
 
  span {
    font-size: 12px;
  }
`;