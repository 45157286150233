import { Suspense, lazy } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { LoadingPage } from './components'

// const currentKey = location.pathname.split('/')[1] || '/';

const currentKey = '';
const animationName = 'rag-fadeIn'
const timeout = { enter: 500, exit: 500 };


const NewLogin = lazy(() => import('./pages/new/auth/login'));
const NewLocatarioConclusao = lazy(() => import ('./pages/new/locatario/Conclusao'));
const NewLocadorConclusao = lazy(() => import ('./pages/new/locador/Conclusao'));

const NewLocadorCadastro = lazy(() => import ('./pages/new/locador/cadastro'));
const NewLocatarioCadastro = lazy(() => import ('./pages/new/locatario/cadastro'));
const NewCriarConta = lazy(() => import('./pages/new/auth/criar-conta'));
const NewTermoLocador = lazy(() => import('./pages/new/locador/termo'));
const NewTermoLocatario = lazy(() => import('./pages/new/locatario/termo'));

const NewHomologacaoLocatario = lazy(() => import('./pages/new/locatario/homologacao'));
const NewHomologacaoLocador = lazy(() => import('./pages/new/locador/homologacao'));

const Home = lazy(() => import('./pages/home/landingPage'));
const About = lazy(() => import('./pages/home/about'));
const ToLessors = lazy(() => import('./pages/home/toLessors'));
const ToRenters = lazy(() => import('./pages/home/toRenters'));

// const LandingPage = lazy(() => import('./pages/landingPage'))
const Login = lazy(() => import('./pages/auth/login'));
const CriarConta = lazy(() => import('./pages/auth/modalidade'))

const ServicosEmExecucao = lazy(() => import('./pages/locador/servicosEmExecucao'));
const ServicosAgendados = lazy(() => import('./pages/locador/servicosAgendados') );
const MeusEquipamentos = lazy(() =>  import('./pages/locador/meusEquipamentos') );
const CadastrarEquipamento = lazy(() =>  import('./pages/locador/meusEquipamentos/cadastrar') );
const EditarEquipamento = lazy(() =>  import('./pages/locador/meusEquipamentos/editar') );
const MeusOperadores = lazy(() =>  import('./pages/locador/meusOperadores/') );
const CadastrarOperador = lazy(() =>  import('./pages/locador/meusOperadores/cadastrar') );
const EditarOperador = lazy(() =>  import('./pages/locador/meusOperadores/editar') );
const Dashboard = lazy(() =>  import('./pages/locador/dashboard') );
const MeusDados = lazy(() => import('./pages/locador/meusDados'));
const ItemsLocador = lazy(() => import('./pages/locador/itens'));
const LocadorOrcamentoBid = lazy(() => import('./pages/locador/bid'));
const LocadorOrcamentoBidDetalhe = lazy(() => import('./pages/locador/bid/detalhe'));

const Usuarios = lazy(() => import('./pages/locatario/usuarios'))
const CadastrarUsuario = lazy(() => import('./pages/locatario/usuarios/cadastrar'))
const EditarUsuario = lazy(() => import('./pages/locatario/usuarios/editar'))

// const LocadorStep01 = lazy(() => import('./pages/locador/cadastro/step01'));
// const LocadorStep02 = lazy(() => import('./pages/locador/cadastro/step02'));
// const LocadorStep03 = lazy(() => import('./pages/locador/cadastro/step03'));
// const LocadorStep04 = lazy(() => import('./pages/locador/cadastro/step04'));
// const LocadorStep05 = lazy(() => import('./pages/locador/cadastro/step05'));
// const LocadorStep06 = lazy(() => import('./pages/locador/cadastro/step06'));
// const LocadorStep07 = lazy(() => import('./pages/locador/cadastro/step07'));
// const LocadorStep08 = lazy(() => import('./pages/locador/cadastro/step08'));
//
// const LocatarioStep01 = lazy(() => import('./pages/locatario/cadastro/step01'))
// const LocatarioStep02 = lazy(() => import('./pages/locatario/cadastro/step02'))
// const LocatarioStep03 = lazy(() => import('./pages/locatario/cadastro/step03'))
// const LocatarioStep04 = lazy(() => import('./pages/locatario/cadastro/step04'))
// const LocatarioStep04Boleto = lazy(() => import('./pages/locatario/cadastro/step04Boleto'))
// const LocatarioStep04CartaoCredito = lazy(() => import('./pages/locatario/cadastro/step04CartaoCredito'))
// const LocatarioStep05 = lazy(() => import('./pages/locatario/cadastro/step05'))
// const LocatarioStep06 = lazy(() => import('./pages/locatario/cadastro/step06'))

const LocatarioHome = lazy(() => import('./pages/locatario/home'))
const LocatarioDashboard = lazy(() => import('./pages/locatario/dashboard'))
const LocatarioServicosAgendados = lazy(() => import('./pages/locatario/servicosAgendados'))
const LocatarioMeusDados = lazy(() => import('./pages/locatario/meusDados'))
const LocatarioMinhasContratacoes = lazy(() => import('./pages/locatario/minhasContratacoes'))
const LocatarioOrcamento = lazy(() => import('./pages/locatario/minhasContratacoes/orcamento'))
const LocatarioBid = lazy(() => import('./pages/locatario/bid'))
const LocatarioBidDetalhe = lazy(() => import('./pages/locatario/bid/detalhe'))

const AdministradorHome = lazy(() => import('./pages/administrador/home'))
const AdministradorUsers = lazy(() => import('./pages/administrador/users'))
const AdministradorNewUsers = lazy(() => import('./pages/administrador/novos-usuarios'))
const AdministradorModelos = lazy(() => import('./pages/administrador/modelos'))
const AdministradorItens = lazy(() => import('./pages/administrador/itens'))
const AdministradorMeusDados = lazy(() => import('./pages/administrador/meusDados'))
const AdministradorLocatarios = lazy(() => import('./pages/administrador/locatarios'))
const AdministradorLocatariosDetalhe = lazy(() => import('./pages/administrador/locatarios/detalhe'))
const AdministradorLocatariosHomologados = lazy(() => import('./pages/administrador/locatarios/homologados'))
const AdministradorLocatarioEquipamentosHomologados = lazy(() => import('./pages/administrador/locatarios/equipamentos'))
const AdministradorLocatarioItensHomologados = lazy(() => import('./pages/administrador/locatarios/itens'))
const AdministradorLocadores = lazy(() => import('./pages/administrador/locadores'))
const AdministradorLocadoresDetalhe = lazy(() => import('./pages/administrador/locadores/detalhe'))
const AdministradorLocadorEquipamentos = lazy(() => import('./pages/administrador/locadores/equipamentos'))
const AdministradorLocadoresOperadores = lazy(() => import('./pages/administrador/locadores/operador'))
const AdministradorLocadoresOperadoresDetalhe = lazy(() => import('./pages/administrador/locadores/operador/detalhe'))
const LocatarioOrcamentoAdm = lazy(() => import('./pages/administrador/orcamento'))
const AdministradorBid = lazy(() => import('./pages/administrador/bid'))
const AdministradorBidDetalhe = lazy(() => import('./pages/administrador/bid/detalhe'))
const Demo = lazy(() => import('./pages/demo'))

const LandingPageLight = lazy(() => import('./pages/landing-page/light'))
const LandingPageHard = lazy(() => import('./pages/landing-page/hard'))
const LandingPageHardObs = lazy(() => import('./pages/landing-page/obs'))
const Thanks = lazy(() => import('./pages/landing-page/thanks'))

const PreviewPageLight = lazy(() => import('./pages/landing-page/preview/light'))
const PreviewPageHard = lazy(() => import('./pages/landing-page/preview/hard'))
// const Test = lazy(() => import('./pages/landing-page'))
const Routes = () => (
  <Router>
    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
      <Suspense fallback={<LoadingPage />}>
        <Switch>

          {/* <Route exact path="/" component={LandingPage} /> */}
          {/*<Route exact path="/test" component={NewLogin} />*/}
          <Route exact path="/test" component={NewHomologacaoLocatario} />
          {/*<Route exact path="/test/termo-locador" component={NewTermoLocador} />*/}


          <Route exact path="/" component={Home} />
          {/*<Route exact path="/test" component={Test} />*/}
          <Route exact path="/sobre-nos" component={About} />
          <Route exact path="/para-locadores" component={ToLessors} />
          <Route exact path="/para-locatarios" component={ToRenters} />

          <Route exact path="/landing/light" component={LandingPageLight} />
          <Route exact path="/landing/hard" component={LandingPageHard} />
          <Route exact path="/landing/hard/obs" component={LandingPageHardObs} />
          <Route exact path="/landing/light/thanks" component={Thanks} />
          <Route exact path="/landing/hard/thanks" component={Thanks} />
          <Route exact path="/landing/hard/obs/thanks" component={Thanks} />

          <Route exact path="/landing/preview/light" component={PreviewPageLight} />
          <Route exact path="/landing/preview/hard" component={PreviewPageHard} />

          <Route exact path="/criar-conta" component={NewCriarConta} />
          <Route exact path="/login" component={NewLogin} />
          <Route exact path="/auth/login" component={Login} />

          <Route exact path="/locador" component={ServicosEmExecucao} />
          <Route exact path="/locador/criar-conta" component={NewLocadorCadastro} />
          {/*<Route exact path="/locador/criar-conta/endereco" component={LocadorStep02} />*/}
          {/*<Route exact path="/locador/criar-conta/documentos" component={LocadorStep03} />*/}
          {/*<Route exact path="/locador/criar-conta/dados-bancarios" component={LocadorStep04} />*/}
          {/*<Route exact path="/locador/criar-conta/equipamento" component={LocadorStep05} />*/}
          {/*<Route exact path="/locador/criar-conta/operador" component={LocadorStep06} />*/}
          <Route exact path="/locador/criar-conta/homologacao" component={NewHomologacaoLocador} />
          <Route exact path="/locador/criar-conta/termos-de-uso" component={NewTermoLocador} />
          <Route exact path="/locador/criar-conta/conclusao" component={NewLocadorConclusao} />
          <Route exact path="/locador/servicos-em-execucao" component={ServicosEmExecucao} />
          <Route exact path="/locador/servicos-agendados" component={ServicosAgendados} />
          <Route exact path="/locador/meus-equipamentos" component={MeusEquipamentos} />
          <Route exact path="/locador/meus-equipamentos/cadastrar" component={CadastrarEquipamento} />
          <Route exact path="/locador/meus-equipamentos/:id/editar" component={EditarEquipamento} />
          <Route exact path="/locador/meus-operadores" component={MeusOperadores} />
          <Route exact path="/locador/meus-operadores/cadastrar" component={CadastrarOperador} />
          <Route exact path="/locador/meus-operadores/:id/editar" component={EditarOperador} />
          <Route exact path="/locador/dashboard" component={Dashboard} />
          <Route exact path="/locador/meus-dados" component={MeusDados} />
          <Route exact path="/locador/itens" component={ItemsLocador} />
          <Route exact path="/locador/bid" component={LocadorOrcamentoBid} />
          <Route exact path="/locador/bid/detalhe/:id" component={LocadorOrcamentoBidDetalhe} />

          <Route exact path="/locatario/usuarios" component={Usuarios} />
          <Route exact path="/locatario/usuarios/cadastrar" component={CadastrarUsuario} />
          <Route exact path="/locatario/usuarios/:id/editar" component={EditarUsuario} />

          <Route exact path="/locatario" component={LocatarioHome} />
          <Route exact path="/locatario/criar-conta" component={NewLocatarioCadastro} />
          {/*<Route exact path="/locatario/criar-conta/endereco" component={LocatarioStep02} />*/}
          {/*<Route exact path="/locatario/criar-conta/documentos" component={LocatarioStep03} />*/}
          {/*<Route exact path="/locatario/criar-conta/financeiro" component={LocatarioStep04} />*/}
          {/*<Route exact path="/locatario/criar-conta/financeiro/boleto" component={LocatarioStep04Boleto} />*/}
          {/*<Route exact path="/locatario/criar-conta/financeiro/cartao-credito" component={LocatarioStep04CartaoCredito} />*/}
          <Route exact path="/locatario/criar-conta/homologacao" component={NewHomologacaoLocatario} />
          <Route exact path="/locatario/criar-conta/termos-de-uso" component={NewTermoLocatario} />
          <Route exact path="/locatario/criar-conta/conclusao" component={NewLocatarioConclusao} />
          <Route exact path="/locatario/dashboard" component={LocatarioDashboard} />
          <Route exact path="/locatario/servicos-agendados" component={LocatarioServicosAgendados} />
          <Route exact path="/locatario/meus-dados" component={LocatarioMeusDados} />
          <Route exact path="/locatario/minhas-contratacoes" component={LocatarioMinhasContratacoes} />
          <Route exact path="/locatario/minhas-contratacoes/:id/orcamento" component={LocatarioOrcamento} />
          <Route exact path="/locatario/rebusca/:id" component={LocatarioHome} />
          <Route exact path="/locatario/bid" component={LocatarioBid} />
          <Route exact path="/locatario/bid/new" component={LocatarioHome} />
          <Route exact path="/locatario/bid/detalhe/:id" component={LocatarioBidDetalhe} />

          <Route exact path="/administrador" component={AdministradorHome} />
          <Route exact path="/administrador/usuarios"component={AdministradorUsers} />
          <Route exact path="/administrador/novos-usuarios"component={AdministradorNewUsers} />
          <Route exact path="/administrador/modelos" component={AdministradorModelos} />
          <Route exact path="/administrador/itens" component={AdministradorItens} />
          <Route exact path="/administrador/meus-dados" component={AdministradorMeusDados} />
          <Route exact path="/administrador/locatarios" component={AdministradorLocatarios} />
          <Route exact path="/administrador/locatarios/:id/detalhe" component={AdministradorLocatariosDetalhe} />
          <Route exact path="/administrador/locatarios/:id/detalhe/:empresaId/homologados" component={AdministradorLocatariosHomologados} />
          <Route exact path="/administrador/locatarios/:id/detalhe/homologados/equipamentos/:idHomologado/:idLocador" component={AdministradorLocatarioEquipamentosHomologados} />
          <Route exact path="/administrador/locatarios/:id/detalhe/homologados/adicionais/:idHomologado/:idLocador" component={AdministradorLocatarioItensHomologados} />

          <Route exact path="/administrador/locadores" component={AdministradorLocadores} />
          <Route exact path="/administrador/locadores/:id/detalhe" component={AdministradorLocadoresDetalhe} />
          <Route exact path="/administrador/locadores/:id/equipamentos" component={AdministradorLocadorEquipamentos} />
          <Route exact path="/administrador/locadores/:id/operadores" component={AdministradorLocadoresOperadores} />
          <Route exact path="/administrador/locadores/:id/operadores/detalhe" component={AdministradorLocadoresOperadoresDetalhe} />
          <Route exact path="/administrador/:id/orcamento" component={LocatarioOrcamentoAdm} />

          <Route exact path="/administrador/bid" component={AdministradorBid} />
          <Route exact path="/administrador/bid/:id/detalhe" component={AdministradorBidDetalhe} />

          <Route exact path="/demo" component={Demo} />

        </Switch>
      </Suspense>
    </CSSTransition>
  </Router>
);

export default Routes;