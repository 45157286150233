import styled, { css } from "styled-components";
import { IButton } from './props'
import { theme } from '../../styled/theme'

export type DefaultButtonProps = {} & Pick<IButton, 'disabled' | 'isFull' | 'variant' | 'size' | 'padding'>

const modifiers = {
  isFull: () => css`
    width: 100%;
  `,

  light: () => css`
    background: ${theme.background};
    color: ${theme.primary[0]};
  `,

  primary: () => css`
    background: ${theme.primary[0]};
    color: #FFF;
  `,
  
  secondary: () => css`
  background: ${theme.secondary[0]};
    color: #FFF;
  `,

  warning: () => css`
    background: ${theme.warning};
    color: #FFF;
  `,


  
  noStyle: () => css`
    background: none;
    color: #FFF;

    svg {
      margin-right: 5px;
      color: ${theme.secondary[0]}
    }
  `,
 
  small: ({padding}) => css`
    padding: ${padding !== null && padding !== undefined ? padding : '0px 10px'};
    font-size: ${theme.fontSize.small};
  `,
  medium: ({padding}) => css`
    padding: ${padding !== null && padding !== undefined ? padding : '3px 25px'};
    font-size: ${theme.fontSize.medium};
  `,
  large: ({padding}) => css`
    padding: ${padding !== null && padding !== undefined ? padding : '5px 35px'};
    font-size: ${theme.fontSize.large};
  `,
}

export const WrapperButton = styled.button<DefaultButtonProps>`
  ${({ theme, disabled, isFull, variant, size, padding }) => css`
    border: none;
    background: none;
    border-radius: 25px;
    font-weight: ${theme.fontWeight.medium};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    
    ${variant === 'light' && modifiers.light() }
    ${variant === 'primary' && modifiers.primary() }
    ${variant === 'secondary' && modifiers.secondary() }
    ${variant === 'no-style' && modifiers.noStyle() }
    ${variant === 'warning' && modifiers.warning() }

    ${size === 'small' && modifiers.small({padding})}
    ${size === 'medium' && modifiers.medium({padding})}
    ${size === 'large' && modifiers.large({padding})}

    ${isFull && modifiers.isFull()}
  `}

  :hover, :disabled {
    opacity: 0.7;
  } 
  
`;


export const Svg = styled.svg`  
  #dots #dot1{
    animation: load 1s infinite;
  }

  #dots #dot2{
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }

  #dots #dot3{
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }

  @keyframes load{
    0%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
 
`;