import {BsWhatsapp} from "react-icons/bs";
import React from "react";
import {WTLink} from "./styled";

type WhatsappButtonProps = {
    whatsAppLink?: string,
}

const whatsLink = "https://api.whatsapp.com/send?phone=5519996441404";

export const WhatsappButton = ({whatsAppLink = whatsLink}:WhatsappButtonProps) => {
    return (
        <WTLink href={whatsAppLink} target="_blank" title="Entre em contato pelo Whatsapp."  >
            <BsWhatsapp fill={"#FFF"} />
        </WTLink>
    );
}