import styled from "styled-components"
 

type InputTypes = {
  type: string
  className?: string
  name?: string
  placeholder?: string
  prefix?: string
  onChange: () => void
}

export const MyInput = ({ type, className = 'form-control', name, placeholder, prefix, onChange, ...rest }:InputTypes) => {
  return (
    <WrapperInput>
      { prefix && <Prefix>{prefix}</Prefix> }
      <Input 
        {...rest}
        type={type}
        className={className}
        name={name}
        placeholder={placeholder}
        prefix={prefix}
        onChange={onChange}
      /> 
    </WrapperInput> 
  )
}
  
const Prefix = styled.label`
  color: #333; 
  margin-top: 8px;
  margin-left: 10px;
  position: absolute;
  font-size: 16px
`;
  
const Input = styled.input`
  padding: 6px 10px 6px 40px;
 
`;

const WrapperInput = styled.div``;